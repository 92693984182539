<template>
    <div>
        <div class="header-wrapper">
            <div class="logo">
                <div class="logoInner">
                    <router-link to="/dashboard">
                        <!-- <img src="@/assets/images/logo.svg" alt="image" /> -->
                        <img src="@/assets/images/logo/logo-2.png" alt="image" />
                    </router-link>
                </div>
                <div class="icon" @click="barMenu">
                    <MenuOutlined />
                </div>
            </div>
            <div class="header-control">
                <div class="header-inner">
                    <div class="location d-flex align-items-center">
                        
                        <!-- <a-dropdown v-if="accessPermission != 0">
                            <a class="ant-dropdown-link" @click.prevent>
                                Programs
                                <DownOutlined />
                            </a>
                            <template #overlay>
                                <a-menu>
                                    <a-sub-menu v-for="program in programsList" :key="program.id" :title="program.name">
                                        <a-menu-item>Fortis</a-menu-item>
                                        <a-menu-item>Max</a-menu-item>
                                        <a-menu-item>Gracian</a-menu-item>
                                        <a-menu-item>PGI</a-menu-item>
                                    </a-sub-menu>
                                </a-menu>
                            </template>
                        </a-dropdown> -->
    
                        <!-- <a-dropdown :trigger="['click']">
                            <a class="ant-dropdown-link" @click.prevent>
                                <div class="name">Location <DownOutlined /></div>
                            </a>
                            <template #overlay>
                                <a-menu>
                                <a-menu-item key="0">
                                    <a href="javascript:void(0)">Organisation 1</a>
                                </a-menu-item>
                                <a-menu-item key="1">
                                    <a href="javascript:void(0)">Organisation 2</a>
                                </a-menu-item>
                                <a-menu-item key="3">
                                    <a href="javascript:void(0)">Organisation 3</a></a-menu-item
                                >
                                <a-menu-item key="4">
                                    <a href="javascript:void(0)">Organisation 4</a></a-menu-item
                                >
                                </a-menu>
                            </template>
                        </a-dropdown> -->
                    </div>
                    <div class="bar-menu">
                        <div class="search-icon" @click="toggle = !toggle">
                            <SearchOutlined />
                        </div>
                        <div class="ellipse-icon" @click="ellipse = !ellipse">
                            <MoreOutlined />
                        </div>
                    </div>
                    <div class="search" v-if="arrayToObjact(screensPermissions, 65) || arrayToObjact(screensPermissions, 40)">
                        <HeaderSearch v-model:value="value" @handleChange="handleChange($event)" />
                    </div>
    
                    <div class="profile" :class="ellipse ? 'show' : ''">
                        
                        <div class="quick-actions d-flex align-items-center">
                            <a-dropdown :trigger="['click']">
                                <a class="ant-dropdown-link" @click.prevent>
                                    <div class="name">
                                        {{ $t("header.quickAction") }}
                                        <DownOutlined />
                                    </div>
                                </a>
                                <template #overlay>
                                    <a-menu class="headerDropdown">
                                        <a-menu-item key="0" v-if="arrayToObjact(screensPermissions, 112)">
                                            <a href="javascript:void(0)" @click="addAppt">{{
                                          $t("header.addAppointment")
                                        }}</a>
                                        </a-menu-item>
                                        <a-menu-item key="1" v-if="arrayToObjact(screensPermissions, 62)">
                                            <a href="javascript:void(0)" @click="addPatient" v-if="route.name != 'PatientSummary'">{{
                                            $t("header.addPatient")
                                            }}</a>
                                    
                                                <a v-else href="javascript:void(0)"  :disabled="route.name == 'PatientSummary' ? true : false">{{
                                            $t("header.addPatient")
                                            }}</a>
                                        </a-menu-item>
                                        <a-menu-item key="4" v-if="arrayToObjact(screensPermissions, 113)">
                                            <a href="javascript:void(0)" @click="addTask">{{
                                          $t("header.addTask")
                                        }}</a>
                                        </a-menu-item>
                                        <a-menu-item key="4" v-if="arrayToObjact(screensPermissions, 107)">
                                            <a href="javascript:void(0)" @click="showStartCallModal(true)">{{ $t("header.startCall") }}</a>
                                        </a-menu-item>
                                        <a-menu-item key="5" v-if="arrayToObjact(screensPermissions, 403)">
                                            <a href="javascript:void(0)" @click="showEscalationModal(true)">{{ 'Add Escalation' }}</a>
                                        </a-menu-item>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="notifications">
                            <a-dropdown :trigger="['click']" overlayClassName="notifications">
                                <a class="ant-dropdown-link" @click.prevent>
                                    <div class="icon">
                                        <a-badge :count="requestCall ? requestCall.length : ''" :number-style="{ backgroundColor: '#267dff' }">
                                            <CalendarOutlined />
                                        </a-badge>
                                    </div>
                                </a>
                                <template #overlay>
                                    <a-menu class="headerDropdown" style="max-height: 400px; overflow: auto">
                                        <a-menu-item class="title">{{ 'Call Request' }}</a-menu-item>
                                        <a-menu-item class="listing" v-for="(reqCall, index) in requestCall" :key="index">
                                            
                                                <!-- <a class="d-flex align-items-center"> -->
                                                    <div class="flex-grow-1 summary" style="padding:10px" v-if="reqCall?.patient?.id">
                                                        <h3><router-link class="bluecolor" :to="{ name: 'PatientSummary', params: { udid: reqCall?.patient?.id  },query:{filter:filter} }">{{ reqCall.patient.fullName  }}</router-link></h3>
                                                        <strong style="padding-left:10px">Time : </strong> {{ reqCall.contactTime.name }}
    
                                                    </div>
                                                <!-- </a> -->
                                                <div class="steps-action" style="padding-bottom:15px;margin-right:10px" v-if="reqCall?.patient?.id">
                                                    <a-button style="margin-right: 10px" @click="rejectReqCall(reqCall?.patient?.id,reqCall?.id)">{{'Reject'}}</a-button>
                                                    <a-button type="primary" @click="requestCallNotification(reqCall?.patient?.id,reqCall.patient?.fullName,reqCall?.id)">{{'Accept'}}</a-button>
                                                </div>
    
                                        </a-menu-item>
                                        <a-menu-item class="allNotication">
                                            <router-link to="/request-call-notifications">{{'Check All Request'}}</router-link>
                                        </a-menu-item>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="notifications">
                            <a-dropdown :trigger="['click']" overlayClassName="notifications">
                                <a class="ant-dropdown-link" @click.prevent>
                                    <div class="icon">
                                        <a-badge :count="count ? count : ''" :number-style="{ backgroundColor: '#267dff' }">
                                            <NotificationOutlined />
                                        </a-badge>
                                    </div>
                                </a>
                                <template #overlay>
                                    <a-menu class="headerDropdown" style="max-height: 400px; overflow: auto">
                                        <a-menu-item class="title">{{ $t("header.notification") }}</a-menu-item>
                                        <a-menu-item class="listing" v-for="(notification, index) in notifications" :key="index">
                                            <router-link :to="notification?.type == 'Appointment' || notification?.type == 'task' ? '': '/communications'" @click=" isReadNotification(notification?.id, notification?.type,notification?.type_id)">
                                                <a class="d-flex align-items-center" href="#">
                                                    <div class="flex-grow-1 ms-3 summary">
                                                        <h3>{{ notification.title }}</h3>
                                                        <p>{{ notification.body }}</p>
                                                        <br />
                                                        <strong class="" v-if="notification.time">{{
                                                        dateOnlyFormat(date) === dateOnlyFormat(notification.time)
                                                          ? 'Today'
                                                          : dateOnlyFormat(notification.time)
                                                      }}</strong>&nbsp;
                                                        <strong class="" v-if="notification.time">{{
                                                        meridiemFormatFromTimestamp(notification.time)
                                                      }} </strong>
                                                    </div>
                                                </a>
                                            </router-link>
                                        </a-menu-item>
                                        <a-menu-item class="allNotication">
                                            <router-link to="/notifications">{{$t('header.checkAllNotifications')}}</router-link>
                                        </a-menu-item>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="profile-menu">
                            <a-dropdown :trigger="['click']">
                                <a class="ant-dropdown-link" @click.prevent>
                                    <div class="name">{{ deCodeString(key,userName?.user?.lastName) +', '}} {{ deCodeString(key,userName?.user?.firstName)+' ' }}  {{ deCodeString(key,userName?.user?.middleName) }}</div>
                                    <div class="image">
                                        <img src="../../../assets/images/userAvatar.png" alt="image" />
                                    </div>
                                </a>
                                <template #overlay>
                                    <a-menu class="headerDropdown">
                                        <a-menu-item key="0" v-if="userName?.user?.staffUdid">
                                            <router-link :to="{
                                                name: 'CoordinatorSummary',
                                                params: { udid: userName?.user?.staffUdid },
                                              }">
                                                {{ $t("header.myProfile") }}
                                            </router-link>
                                        </a-menu-item>
                                         <a-menu-item key="3" v-if="arrayToObjact(screensPermissions,493)">
                                            <router-link :to="{ name: 'BugReport' }">
                                                Bug Report
                                            </router-link>
                                        </a-menu-item>
                                        <a-menu-item key="3">
                                            <router-link :to="{ name: 'ResetPassword' }">
                                                Reset Password
                                            </router-link>
                                        </a-menu-item>
    
                                        <a-menu-item key="3" >
                                            <!-- <a href="javascript:void(0)">Logout</a> -->
                                            <router-link :to="{ name: 'Logout' }">
                                               Logout
                                            </router-link>
                                        </a-menu-item>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <AddAppointment v-if="appointmentModal" v-model:visible="appointmentModal" @is-visible="showModal($event)" :patientId="patientId" :patientName="patientName" />
        <TasksModal v-if="tasksModal" v-model:visible="tasksModal" @saveTaskModal="handleTaskOk($event)" />
        <PatientsModal v-if="PatientsModal" v-model:visible="PatientsModal" @closeModal="closeAppointModal($event)" />
        <!-- <CoordinatorsModal v-model:visible="CoordinatorsModal" @ok="handleOk" /> -->
        <AddStartCall v-if="AddStartCall" v-model:visible="AddStartCall" @ok="closeStartCallModal" @is-visible="showStartCallModal($event)" />
        <SendMessage v-if="SendMessage" v-model:visible="SendMessage" @ok="startOk" />
        <!---->
        <AppointmentDetails v-if="isAppointment" v-model:visible="isAppointment" @closeModal="closeModal(event)" />
        <EscaltionModal v-if="escalationVisible"  v-model:visible="escalationVisible" @saveModal="saveModal($event)" />
        <TaskDetails v-model:visible="taskVisibleView"  :taskId="taskID" :onlyView="onlyView"/>
    </div>
    </template>
    
    <script>
    import {
        defineComponent,
        ref,
        computed,
        watchEffect,
        onUnmounted,
        defineAsyncComponent,
        onMounted,
    } from "vue";
    
    import SendMessage from "@/components/modals/SendMessage";
    import {
        useStore
    } from "vuex";
    import HeaderSearch from "./HeaderSearch";
    import AppointmentDetails from "../../modals/AppointmentDetails";
    import {
        arrayToObjact,
        meridiemFormatFromTimestamp,
        dobFormat,
        dateOnlyFormat,deCodeString, key
    } from "@/commonMethods/commonMethod";
    import {
        useRoute,
        useRouter
    } from "vue-router";
    import {
        NotificationOutlined,
        DownOutlined,
        MenuOutlined,
        SearchOutlined,
        MoreOutlined,
        CalendarOutlined
    } from "@ant-design/icons-vue";
    export default defineComponent({
        components: {
            AppointmentDetails,
            NotificationOutlined,
            DownOutlined,
            MenuOutlined,
            SearchOutlined,
            MoreOutlined,
            CalendarOutlined,
            AddAppointment: defineAsyncComponent(() =>
                import("@/components/modals/AddAppointment")
            ),
            TasksModal: defineAsyncComponent(() =>
                import("@/components/modals/TasksModal")
            ),
            PatientsModal: defineAsyncComponent(() =>
                import("@/components/modals/PatientsModal")
            ),
            TaskDetails: defineAsyncComponent(() => import("@/components/tasks/modals/TaskDetails.vue")),
            EscaltionModal: defineAsyncComponent(() => import("@/components/escalations/EscalationModal")),
            AddStartCall: defineAsyncComponent(() =>
                import("@/components/modals/AddStartCall")
            ),
            SendMessage,
            HeaderSearch,
        },
        props: {},
        setup(props, {
            emit
        }) {
            const store = useStore();
            const router = useRouter();
            const toggle = ref(false);
            const ellipse = ref(false);
            const tasksModal = ref(false);
            const taskID = ref()
            const onlyView = ref(true)
            const taskVisibleView = ref(false)
            const escalationVisible = ref(false)
            const isAppointment = ref();
            const patientId = ref()
            const patientName =ref()
            const date = Math.round(+new Date() / 1000);
            const userName = JSON.parse(localStorage.getItem("auth"));
            const route = useRoute()
            const logoutUser = () => {
                store.state.authentication.errorMsg = "";
                store.dispatch("logoutUser").then(() => {
                    router.push("/logout");
                })
    
            };
            const value = ref();
    
            function barMenu() {
                var barMenu = JSON.parse(localStorage.getItem("barmenu"));
                if (barMenu == true) {
                    localStorage.setItem("barmenu", JSON.stringify(false));
                } else {
                    localStorage.setItem("barmenu", JSON.stringify(true));
                }
    
                document.body.classList.toggle("show");
            }
    
            watchEffect(() => {
                store.dispatch("notificationList");
                store.dispatch("orderTable", {
                    data: "&orderField=&orderBy=",
                });
    
                if (JSON.parse(localStorage.getItem("barmenu")) == true) {
                    document.body.classList.add("show");
                }
                //document.body.classList.remove("show");
            });
            onMounted(() => {
                store.dispatch("manageProgramList");
                store.dispatch("newRequests");
                store.dispatch("requestCall");
                if (JSON.parse(localStorage.getItem("barmenu")) == true) {
                    document.body.classList.add("show");
                }
            });
            onUnmounted(() => {});
            const appointmentModal = ref(false);
            const addAppt = () => {
                appointmentModal.value = true;
            };
    
            function showModal(event) {
                if (event.date) {
                    appointmentModal.value = event.check;
                    emit("is-heardeVisible", event);
                } else {
                    appointmentModal.value = event;
                }
            }
    
            const addTask = () => {
                tasksModal.value = true;
            };
    
            const showEscalationModal = () => {
                escalationVisible.value = true
            }
    
            const PatientsModal = ref(false);
    
            const addPatient = () => {
                if (route.name != 'PatientSummary') {
                    store.state.patients.addDemographic = null
                   
                    store.state.patients.emergencyContact = null
                    store.state.patients.patientReferralSource = null
                    store.state.patients.responsiblePerson = null
                    store.state.patients.patientConditions = null
                }
                PatientsModal.value = true;
            };
            const closeAppointModal = (status) => {
                PatientsModal.value = status.value;
                if (route.name == 'PatientSummary' && status.value==false) {
                    
                    store.dispatch('patientConditions', route.params.udid);
                    store.dispatch("documents", route.params.udid);
                }
            };
    
            const CoordinatorsModal = ref(false);
            const addCare = () => {
                CoordinatorsModal.value = true;
            };
            const handleOk = () => {
                CoordinatorsModal.value = false;
            };
    
            const handleTaskOk = (e) => {
                tasksModal.value = e;
            };
    
            const AddStartCall = ref(false);
            const showStartCallModal = (e) => {
                AddStartCall.value = e;
            };
            const SendMessage = ref(false);
            const addsendMessage = () => {
                SendMessage.value = true;
            };
            const startOk = () => {
                SendMessage.value = false;
            };
            const closeStartCallModal = () => {
                AddStartCall.value = false;
            };
    
            const notifications = computed(() => {
                return store.state.common.getNotificationsList;
            });
    
            const programsList = computed(() => {
                return store.state.programs.manageProgramList;
            });
    
            const handleChange = (val) => {
                console.log('object',val);
                let checkData = val.split("=>");
                if (checkData[1] === "Patient") {
                    value.value = val;
                    router.push({
                        name: "PatientSummary",
                        params: {
                            udid: checkData[0],
                            globalSearch: true,
                        },
                    })
                    
                } else {
                    value.value = val;
                    router.push({
                        name: "CoordinatorSummary",
                        params: {
                            udid: checkData[0],
                            globalSearch: true,
                        },
                    });
                }
            };
    
            const isReadNotification = (id, type, typeId) => {
                if (type == "Appointment") {
                    
                    store.dispatch("appointmentDetails", typeId);
                    
                    isAppointment.value = true;
                }
                else if(type == "task"){
                    store.dispatch('editTask', {id: typeId})
                taskID.value = typeId
                taskVisibleView.value = true
          } 
                store.dispatch("isReadUpdateNotification",id).then(()=>{
                    store.dispatch("notificationList");
                })
                
            };
            const bitrixFormCheck = computed(() => {
                return store.state.patients.bitrixFormCheck;
            });
    
            function closeModal(status) {
                isAppointment.value = status;
            }
            const saveModal = (value) => {
                escalationVisible.value = value
            }
    
            const requestCallNotification = (pId,value,id) =>{
              appointmentModal.value = true;
              patientId.value = pId
              patientName.value = value
              store.dispatch('isReadCallNotification',{patientId:pId,id:id}).then((resp)=>{
                if(resp==true){
                  store.dispatch("requestCall");
                }
              })
            }
    
            const rejectReqCall = (pId,id) =>{
              store.dispatch('isReadCallNotification',{patientId:pId,id:id}).then((resp)=>{
                if(resp==true){
                  store.dispatch("requestCall");
                }
              })
            }
    
            return {
                requestCall: computed(() => {
                    return store.state.appointment.requestCall
                }),
                rejectReqCall,
                patientId,
                patientName,
                requestCallNotification,
                saveModal,
                escalationVisible,
                showEscalationModal,
                closeModal,
                isAppointment,
                screensPermissions: store.getters.screensPermissions,
                bitrixFormCheck,
                dateOnlyFormat,
                isReadNotification,
                count: store.getters.notificationCount,
                date,
                handleChange,
                dobFormat,
                meridiemFormatFromTimestamp,
                notifications,
                handleTaskOk,
                userName,
                logoutUser,
                value,
                barMenu,
                toggle,
                ellipse,
                SendMessage,
                addsendMessage,
                appointmentModal,
    
                addAppt,
                tasksModal,
                addTask,
                arrayToObjact,
                PatientsModal,
                addPatient,
                closeAppointModal,
                CoordinatorsModal,
                addCare,
                AddStartCall,
                showStartCallModal,
                closeStartCallModal,
                startOk,
                handleOk,
                showModal,
                route,
                programsList,
                taskVisibleView,
                taskID,
                onlyView,
                deCodeString,
                key
            };
        },
    });
    </script>
    